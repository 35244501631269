<template>
    <b-overlay :show='showLoader'>
        <b-card v-if='arePapersLoaded'>
            <b-row>
                <b-col cols='12' sm='6'>
                    <h3>{{ $t('general.papers_list') }}</h3>
                </b-col>
                <b-col cols='12' sm='6' class="text-center text-sm-right">
                    <b-button v-if="addDateShow" variant="warning" class="ml-2" @click="$router.push({name: 'add-research-paper'})">{{ $t('general.add_paper') }}</b-button>
                    <b-button variant="secondary" class="ml-2" @click="onGeneratePDFButtonClicked">{{ $t('general.schoolForm') }}</b-button>
                </b-col>
            </b-row>

            <b-row class="mt-1">
                <b-col>
                    <vue-good-table 
                        ref="table" 
                        :columns='columns' 
                        :rows='rowsShowing'
                        styleClass="vgt-table striped bordered condensed"
                        :pagination-options="{
                            enabled: true,
                            perPage: pageLength,
                        }">

                        <template slot="table-row" slot-scope="props">
                            <span v-if="props.column.field == 'StateID'">
                                <p v-if='props.row.StateID === 1'>
                                    Prijavljena
                                </p>
                                <p v-else-if="props.row.StateID === 2">
                                    Oddana
                                </p>
                            </span>
                            <span v-else-if="props.column.field == 'ID'">
                                <div class="d-flex justify-content-center">
                                    <b-button @click="$router.push({name: 'research-paper', params: {id: props.row.ID }})" variant="info"><feather-icon icon="InfoIcon"/></b-button>
                                </div>
                            </span>
                            <span v-else-if="props.column.field == 'Authors'">
                                {{ getFullnameFromPerson(props.row.Authors) }}
                            </span>
                            <span v-else-if="props.column.field == 'Mentors'">
                                {{ getFullnameFromMentor(props.row.Mentors) }}
                            </span>
                            <span v-else-if="props.column.field == 'Fields'">
                                {{ getPodrocjeNazivFromArray(props.row.Fields) }}                                
                            </span>
                            <span v-else-if="props.column.field == 'TypeID'">
                                <p v-if='props.row.TypeID === 1'>
                                    Raziskovalna naloga
                                </p>
                                <p v-else-if="props.row.TypeID === 2">
                                    Inovacijski predlog
                                </p>
                            </span>
                            <span v-else>
                                {{props.formattedRow[props.column.field]}}
                            </span>
                        </template>

                        <template
                            slot="pagination-bottom"
                            slot-scope="props"
                        >

                            <div class="d-flex justify-content-between flex-wrap">
                                <div class="d-flex align-items-center mb-0 mt-1">
                                    <span class="text-nowrap ">
                                        {{ (($refs.table) ? $refs.table.currentPage : 0) * pageLength - (pageLength - 1) }} - {{ props.total - (($refs.table) ? $refs.table.currentPage : 0) * pageLength > 0 ? (($refs.table) ? $refs.table.currentPage : 0) * pageLength : props.total }} {{ $t('general.of') }} {{ props.total }}
                                    </span>

                                    <b-dropdown
                                        variant="none"
                                        no-caret>

                                        <template v-slot:button-content>
                                            <span>({{ $t('general.per_page') }}: {{pageLength}}) </span>
                                            <feather-icon
                                                icon="ChevronDownIcon"
                                                size="16"
                                                class="text-body align-middle mr-25"
                                            />
                                        </template>

                                        <b-dropdown-item :key="index" v-for="(length, index) in lengths" @click.native="setPageLength(length)">
                                            {{ length }}
                                        </b-dropdown-item>

                                    </b-dropdown>
                                </div>
                                <div>
                                    <b-pagination
                                        :value="1"
                                        :total-rows="props.total"
                                        :per-page="pageLength"
                                        first-number
                                        last-number
                                        align="right"
                                        prev-class="prev-item"
                                        next-class="next-item"
                                        class="mt-1 mb-0"
                                        @input="(value)=>props.pageChanged({currentPage:value})"
                                    >
                                        <template #prev-text>
                                            <feather-icon
                                                icon="ChevronLeftIcon"
                                                size="18"
                                            />
                                        </template>
                                        <template #next-text>
                                            <feather-icon
                                                icon="ChevronRightIcon"
                                                size="18"
                                            />
                                        </template>
                                    </b-pagination>
                                </div>
                            </div>

                        </template>
                    </vue-good-table>
                </b-col>
            </b-row>
           
        </b-card>
        
        <b-modal id="modal-multi-1"  v-model="showModal" hide-header ok-title="Generiraj" cancel-title="Prekliči" @ok="generateForms">
            <p class="my-2">Želite generirati PDF dokument za prijavljene ali oddane naloge?</p>
            <v-select :options="researchPaperStateSelectOptions" v-model="selectedState"></v-select>
        </b-modal>

    </b-overlay>
</template>
<script>
    import {BCol, BRow, BOverlay, BCard, BButton, BPagination, BDropdown, BDropdownItem, BModal} from 'bootstrap-vue'
    import {VueGoodTable} from 'vue-good-table'
    import vSelect from 'vue-select'
    export default {
        components: {BCol, BRow, BOverlay, BCard, BButton, BPagination, BDropdown, BDropdownItem, BModal, VueGoodTable,  vSelect },
        computed: {
            rows() {
                if (!this.papersResults) return 0
                return this.papersResults.length
            }
        },
        data() {
            return {
                rowsShowing: [],

                showLoader: false,
                years: [],
                selected_year: null,
                statuses: [
                    {
                        label: this.$t('general.status_registered'),
                        value: 1
                    },
                    {
                        label: this.$t('general.status_submited'),
                        value: 2
                    }
                ],
                selected_status: null,
                deletePaperID: 0,

                papersResults: [],
                arePapersLoaded: false,


                columns: [
                    {
                        field: 'Title',
                        label:  this.$t('general.title'),
                        filterOptions: {
                            enabled: true
                        }
                    },
                    {
                        field: 'Authors',
                        // field: 'Avtorji',
                        label: this.$t('general.authors'),
                        filterOptions: {
                            enabled: true,
                            filterFn: (data, filter) => data.some((entry) => entry.Firstname.toLowerCase().includes(filter.toLowerCase()) || entry.Lastname.toLowerCase().includes(filter.toLowerCase()))
                        }
                    },
                    {
                        field: 'Mentors',
                        // field: 'Mentorji',
                        label: this.$t('general.menthors'),
                        filterOptions: {
                            enabled: true,
                            filterFn: (data, filter) => data.some((entry) => entry.Firstname.toLowerCase().includes(filter.toLowerCase()) || entry.Lastname.toLowerCase().includes(filter.toLowerCase()))
                        }
                    },
                    {
                        field: 'Fields',
                        // field: 'Podrocja',
                        label: this.$t('general.areas'),
                        filterOptions: {
                            enabled: true,
                            filterFn: (data, filter) => data.some((entry) => entry.Name.toLowerCase().includes(filter.toLowerCase()))
                        }
                    },
                    {
                        field: 'StateID',
                        label: this.$t('general.status'),
                        filterOptions: {
                            enabled: true,
                            filterDropdownItems: [  
                                { value: 1, text: this.$t('general.status_registered') },  
                                { value: 2, text: this.$t('general.status_submited') }
                            ]

                        },
                        sortable: false

                    },
                    {
                        field: 'SchoolYear.Name',
                        label: this.$t('general.school_year'),
                        filterOptions: {
                            enabled: true,
                            filterDropdownItems: [],
                            filterFn: (data, filter) => {
                                return data.toLowerCase() === filter.toLowerCase()
                            }

                        }
                    },
                    {
                        field: 'TypeID',
                        label: this.$t('general.type'),
                        filterOptions: {
                            enabled: true,
                            filterDropdownItems: [
                                { value: 1, text: this.$t('general.type_raziskovalna') },
                                { value: 2, text: this.$t('general.type_inovative') }
                            ]
                        },
                        sortable: false

                    },
                    {
                        field: 'ID',
                        label: '',
                        sortable: false,
                        hidden: false
                    }
                ],

                pageLength: 10,
                lengths: [
                    '10',
                    '20',
                    '50',
                    '100'
                ],
                perPage: 20,
                currentPage: 1,
                addDateShow: false,

                showModal: false,
                researchPaperStateSelectOptions: [
                    {
                        key: 1,
                        label: 'Prijavljene naloge'
                    },
                    {
                        key: 2,
                        label: 'Oddane naloge'
                    }
                ],
                selectedState: null
            }
        },
        methods: {
            onYearFilterChange(val) {
                if (!val) {
                    this.rowsShowing = this.papersResults
                } else {
                    this.rowsShowing = this.papersResults.filter(p => p.SolskoLeto === val)
                }
            },
            getPodrocjeNazivFromArray(podrocja) {
                if (!podrocja) return ''
                return podrocja.map(p => { return p.Name }).join(', ')
            },
            getFullnameFromPerson(peopleArray) {
                if (!peopleArray) return ''
                return peopleArray.map(p => { return `${p.Firstname} ${p.Lastname}` }).join(', ')
            },
            getFullnameFromMentor(peopleArray) {
                if (!peopleArray) return ''
                return peopleArray.map(p => { return `${p.ProfessionalTitle} ${p.Firstname} ${p.Lastname}` }).join(', ')
            },
            setPageLength(length) {
                this.pageLength = length
                this.$refs.table.perPageChanged({currentPerPage:this.pageLength})
            },
            async assembleParalelPromise() {
                const p1 = this.$http.get('/v1/sola/naloga')

                return {
                    papersResponse: await p1
                }
            },
            async loadData() {

                const thisIns = this
                thisIns.showLoader = true

                try {
                    const { papersResponse } = await this.assembleParalelPromise()
                    this.papersResults = papersResponse.data || []

                    this.rowsShowing = this.papersResults

                    thisIns.arePapersLoaded = true
                } catch (error) {
                    thisIns.$printError(`${thisIns.$t('general.load_error')}\n${error.message}`)
                } finally {
                    thisIns.showLoader = false
                }
            },
            getSchoolYears() {
                const thisIns = this
                thisIns.showLoader = true
                thisIns.$http.get('/v1/sola/solskoLeto/')
                    .then((response) => {
                        thisIns.years = response.data

                        const filters = []
                        for (const year of response.data) {
                            if (year.Active === 1) {
                                thisIns.selected_year = year
                            }
                            filters.push({value: year.Name, text: year.Name, sortingId: year.ID})
                        }

                        // sorting date descending (2022/2023 has higher ID than 2021/2022)
                        filters.sort((a, b) => b.sortingId - a.sortingId)

                        const schoolYearColumnIndex = this.columns.findIndex(col => col.field === 'SchoolYear.Name')
                        if (schoolYearColumnIndex !== -1)  {
                            this.$set(this.columns[schoolYearColumnIndex].filterOptions, 'filterDropdownItems', filters)
                            this.$set(this.columns[schoolYearColumnIndex].filterOptions, 'filterValue', filters[0].value)
                        } else {
                            this.$set(this.columns[schoolYearColumnIndex].filterOptions, 'filterDropdownItems', null)
                        }

                        thisIns.showAdd()
                        thisIns.showLoader = false
                    })
                    .catch(error => {
                        thisIns.$printError(`${thisIns.$t('general.load_error')}\n${error.message}`)
                        thisIns.showLoader = false
                    })
            },
            onGeneratePDFButtonClicked() {
                this.showModal = true
            },
            generateForms() {

                if (!this.selectedState) {
                    this.$printWarning(`${this.$t('general.select_state_warning')}`)
                    return
                }

                const thisIns = this
                thisIns.showLoader = true
                thisIns.$http.get(`/v1/sola/prijavnicaPDFSola?state_id=${this.selectedState.key}`)
                    .then(function(response) {
                        thisIns.downloadItem(response.data)
                        thisIns.showLoader = false
                        thisIns.prijavnicaActive = false
                    })
                    .catch(error => {
                        if (error.response.data.includes('no papers to generate')) thisIns.$printError('Ni nalog za generiranje prijavnice!')
                        else thisIns.$printError(`${thisIns.$t('general.load_error')}${error.message}`)
                        thisIns.showLoader = false
                    })
            },
            downloadItem(dobljenID) {
                const url = `/api/v1/sola/prijavnicaPDFSola/${dobljenID}`
                window.open(`${url}.pdf`, '_blank')
            },
            showAdd() {
                const today = this.moment()
                const startDateRegister = this.moment(this.selected_year.StartDateRegister)
                const endDateRegister = this.moment(this.selected_year.EndDateRegister).endOf('day')

                this.addDateShow = today.isBetween(startDateRegister, endDateRegister)
            }
        },
        created() {
            this.getSchoolYears()
            this.selected_status = this.statuses[0]
        },
        async mounted() {
            await this.loadData()
        }
    }
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>